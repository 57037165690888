(function ($, root, undefined) {
  $(function () {
    'use strict'; 
   
    //Internal Link ScrollTo
    $('a[href^="#"]').on('click',function (e) {
      e.preventDefault();
      var target = this.hash,
      $target = $(target); 
      $('html, body').stop().animate({  
        'scrollTop': $target.offset().top
      }, 900, 'swing', function () {
        window.location.hash = target;
      });
    });

    // External Links new tab
    $("a[href^=http]").each(function(){
      if(this.href.indexOf(location.hostname) == -1) {
        $(this).attr({
          target: "_blank",
          title: "Opens in a new window"
        });
      }
    })

    // Responsive iframes
    var $iframes = $('iframe');
    $('iframe')
    $iframes.each(function () {
      $(this).data('ratio', this.height / this.width )
        .removeAttr('width')
        .removeAttr('height');
    });
    $(window).resize(function() {
      $iframes.each(function() {
        var width = $(this).parent().width();
        $(this).width(width)
          .height( width * $(this).data('ratio') );
      });
    }).resize();

    $('select').attr('disabled', 'disabled');
    
    $(".features iframe[src*='https://w.soundcloud.com']").css({
      maxWidth:'500px',
      maxHeight:'400px'
    });

    $('.tax__country').text($('.tax__country').text().replace(/\_/g, ' '));
    $('#acf-_post_title').focus();

    // Stripe
    $(".control-label").text(function(index, text) {
      return text.replace('Card Holder\'s Name', 'Name On Card');
    });
    $(".control-label").text(function(index, text) {
      return text.replace('Card CVV', 'CVV Code');
    });
    $(".control-label").text(function(index, text) {
      return text.replace('Payment Amount', 'Donation Amount');
    });
    $("._100 button").text(function(index, text) {
      return text.replace('Make Payment', 'Support Bad Copy!');
    });
    $("#fullstripe_email").attr('placeholder', 'you@yourdomain.com');
    $("#fullstripe_custom_amount").attr('placeholder', '1.00');
    $("#fullstripe_name").attr('placeholder', 'Chuck Ragan');
      
    // Hamburger
    var menuIcon = document.querySelector('.menu__buttons');
    var menu = menuIcon.parentElement;
    menuIcon.addEventListener('click', function(event){
      if (menu.classList.contains('open')) {
        $('.menu-main-nav-container').slideUp('fast',function() {
          menu.classList.remove('open');  
        });
      } else {
        $('.menu-main-nav-container').slideDown('fast',function() {
          menu.classList.add('open');
        });  
      }
    });

    // Pause Video
    $('.pause').on('click', function(e) {
      e.preventDefault();
      $('iframe#youtube-player-'+$(this).data("videoId"))[0].contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
      $('.ad__video').slideToggle();
    });
    
    // Columns Submenu
    var $columnsMenu = $('.columns__menu').detach();
    
    if(parseInt($(window).width()) >= 768) {
      $('#menu-item-2756').hover(function(){ // 2756
        $(this).append($columnsMenu);
        $('.submenu').slideDown('fast',function() {
          $('.submenu').css({display:'block'});
        });
      });
      $('#menu-item-2756').mouseleave(function(){
        $('.submenu').slideUp('fast',function() {
          $($columnsMenu).remove();
        });
      });
    }
    
    // Features Submenu
    var $featuresMenu = $('.features__menu').detach(); 

    if(parseInt($(window).width()) >= 768) {
      $('#menu-item-3581').hover(function(){ // 3581
        $(this).append($featuresMenu);
        $('.submenu').slideDown('fast',function() {
          $('.submenu').css({display:'block'});
        });
      });
      $('#menu-item-3581').mouseleave(function() {
        $('.submenu').slideUp('fast',function() { 
          $($featuresMenu).remove();
        });
      });
    } 
    
    $('.everest-forms').prepend( $('<h2 class="section__label"><span class="section__label__mask">Enter <?php the_title(); ?> Contest</span></h2>'))
    
    // Gallery 
    $('.gallery').prepend( $('<h2 class="section__label"><span class="section__label__mask">Photos</span></h2>'))
    
    // Share List Scroll
    var win = $(this);
    if (win.width() < 980 ) {
      $(window).scroll(function() {
        if (jQuery(this).scrollTop() > 100 ) {
          $('.share-list').slideDown();
        } else {
          $('.share-list').slideUp();
        }
      });
    }
    
    $('.term-slider').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      centerMode: false,
      autoplay: true,
      autoplaySpeed: 5000,
      responsive: [
        {
          breakpoint: 980,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2            
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1            
          }
        }
      ]
    });

      $('.product__slider').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      centerMode: false
    });

    // Infinite Scroll
    var $container = $('.browseBands');
    $container.infiniteScroll({
      append: '.browse__item',
      hideNav: '.pagination',
      path: '.pagination .next',
      scrollThreshold: 500,
      status: '.page-load-status'
        
    });
    $container.on( 'error.infiniteScroll', function( event, error, path ) {
      console.log( 'Could not load: ' + path )
    });

    // Submit News
    $("div.af-submit").hide();
		$("div.acf-field-5dc8a92089676").hide();
		$(".submit-news-selected,.submit-stream-selected,.submit-tour-selected,.submit-video-selected,.submit-review-selected").hide();

		$("input[name$='acf[field_58619925792a1]']").click(function() {
    $("div.af-submit,div.acf-field-5dc8a92089676").slideDown('slow');
    $("div.af-field-news-type").slideUp('slow');

    $('html, body').animate({
      scrollTop: $('.af-form').offset().top
    }, 1000);

    if ($("input[name$='acf[field_58619925792a1]']:checked").val() == "news") {
      $(".submit-news-selected").show();
      $(".submit-stream-selected,.submit-tour-selected,.submit-video-selected,.submit-review-selected").hide();
    }
    if ($("input[name$='acf[field_58619925792a1]']:checked").val() == "stream") {
      $(".submit-stream-selected").show();
      $(".submit-news-selected,.submit-tour-selected,.submit-video-selected,.submit-review-selected").hide();
    }
    if ($("input[name$='acf[field_58619925792a1]']:checked").val() == "tour") {
      $(".submit-tour-selected").show();
      $(".submit-news-selected,.submit-stream-selected,.submit-video-selected,.submit-review-selected").hide();
    }
    if ($("input[name$='acf[field_58619925792a1]']:checked").val() == "video") {
      $(".submit-video-selected").show();
      $(".submit-news-selected,.submit-stream-selected,.submit-tour-selected,.submit-review-selected").hide();
    }
    if ($("input[name$='acf[field_58619925792a1]']:checked").val() == "review") {
      $(".submit-review-selected").show();
      $(".submit-news-selected,.submit-stream-selected,.submit-tour-selected,.submit-video-selected").hide();
    }
  });
  
  $(".acf-table thead").hide();
  $(".acf-actions .acf-button").click(function() {
    $(".acf-table thead").slideDown('slow');
  });

  $(".submit-back").click(function() {
    $("div.af-field-news-type").slideDown('slow');
    $('html, body').animate({
      scrollTop: $('.af-form').offset().top
    }, 1000);
  });
  
  // Shop 
  $(".wp-asp-grid-item").click(function() {
    window.location = $(this).find("a").attr("href"); 
    return false;
  });
  
});

})(jQuery, void 0);